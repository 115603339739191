@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "IBM Plex Mono", monospace;
  color: #fff;
  display: flex;
  flex-direction: column;
  background: #1c1d27;
  font-weight: 400;
}

.App {
  min-height: 100vh;
  position: relative;
}

.container {
  height: 100%;
  position: relative;
  min-height: 100vh;
  background: #1c1d27;
  isolation: isolate;
  height: 100%;
  display: flex;
  flex-direction: column;
}

button {
  font-family: inherit;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.header {
  position: relative;
  z-index: 3;
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 70%;
  z-index: 2;
  margin: auto;
}

.content {
  flex-grow: 1;
  flex-basis: 100%;
  width: 100%;
  margin-top: -8rem;
}

.header {
  padding-top: 2.875rem;
  padding-left: 3.25rem;
  padding-right: 3.25rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.text {
  background: rgba(28, 29, 39, 0.9);
  border: 0.125rem solid rgba(208, 235, 195, 0.6);
  /* backdrop-filter: blur(1.25rem); */
  max-width: 62.5rem;
  width: 100%;
  border-radius: 1.125rem;
  -webkit-border-radius: 1.125rem;
  -moz-border-radius: 1.125rem;
  -ms-border-radius: 1.125rem;
  -o-border-radius: 1.125rem;
  min-height: 26.1875rem;
  display: flex;
  padding: 5rem 2rem;
  align-items: center;
}

.logoContainer {
  position: relative;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(5em);
  width: max-content;
  margin: auto;
}

.logoContainer img {
  max-width: 11.75rem;
}

.text p {
  max-width: 55ch;
  margin: auto;
  text-align: center;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.9375rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.header {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.9375rem;
  color: #c4e8b3;
  padding-bottom: min(5rem, 4vw);
}

.header a {
  text-decoration: none;
  color: #c4e8b3;
  position: relative;
}

.textContainer {
  min-height: 450px;
  margin-top: -2rem;
}

.header a::after,
.mobLinks a::after {
  content: "";
  position: absolute;
  bottom: -0.125rem;
  left: 0;
  width: 100%;
  height: 0.125rem;
  background: #c4e8b3;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -ms-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
}

.header a:hover::after,
.mobLinks a:hover::after {
  transform: scaleX(1);
}

.header button {
  cursor: pointer;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.9375rem;
  display: flex;
  align-items: center;
  text-align: center;
  font-family: inherit;
  color: #c4e8b3;
  background-color: transparent;
  border: 0.0625rem solid #d0ebc3;
  border-radius: 1.125rem;
  padding: 0.4375rem 1.75rem;
  -webkit-border-radius: 1.125rem;
  -moz-border-radius: 1.125rem;
  -ms-border-radius: 1.125rem;
  -o-border-radius: 1.125rem;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.mint {
  padding: 0.6875rem 1.5625rem;
  border: 0.0625rem solid #d0ebc3;
  border-radius: 1.125rem;
  -webkit-border-radius: 1.125rem;
  -moz-border-radius: 1.125rem;
  -ms-border-radius: 1.125rem;
  -o-border-radius: 1.125rem;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.625rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #c4e8b3;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: min(5rem, 4vw);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.mint:hover,
.header button:hover {
  background-color: #c4e8b3;
  color: #1c1d27;
}

.border {
  height: 0.125rem;
  background-color: #fef2b2;
  content: "";
  max-width: 63.0625rem;
  width: 100%;
  margin-top: min(5rem, 4vw);
  margin-bottom: 1.25rem;
}

.bottom {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.4375rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
}

.bottom span {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.bottom span svg line {
  fill: #c4e8b3;
}

.mob {
  display: none;
}

.mob svg {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.mob svg:active {
  fill: #c4e8b3;
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -ms-transform: scale(0.6);
  -o-transform: scale(0.6);
}

.mobLinks {
  min-height: 430px;
  width: 100%;
}

.mobLinks a {
  margin: auto;
  position: relative;
  font-size: 1.5rem;
  line-height: 1.9375rem;
  font-weight: 400;
  display: block;
  text-decoration: none;
  margin-bottom: min(4rem, 20.5vw);
  color: #c4e8b3;
  text-align: center;
  width: max-content;
}

.mobLinks a:last-child {
  margin-bottom: 0;
}

/* Loader */

.bookshelf_wrapper {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.6);
  -webkit-transform: translate(-50%, -50%) scale(0.6);
  -moz-transform: translate(-50%, -50%) scale(0.6);
  -ms-transform: translate(-50%, -50%) scale(0.6);
  -o-transform: translate(-50%, -50%) scale(0.6);
}

.books_list {
  margin: 0 auto;
  width: 300px;
  padding: 0;
}

.minted {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 18rem;
  width: 100%;
  margin: 2rem 0 0 0;
}

.ethscanlink {
  font-size: 1rem !important;
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
}

.minted > img {
  border-radius: 6px;
  margin-top: 1.5rem;
  height: 100%;

  /* height: 100%; */
  margin-bottom: 1rem;
}

.book_item {
  position: absolute;
  top: -120px;
  box-sizing: border-box;
  list-style: none;
  width: 40px;
  height: 120px;
  opacity: 0;
  background-color: transparent;
  border: 5px solid white;
  transform-origin: bottom left;
  transform: translateX(300px);
  -webkit-animation: travel 2500ms linear infinite;
  animation: travel 2500ms linear infinite;
}
.book_item.first {
  top: -140px;
  height: 140px;
}
.book_item.first:before,
.book_item.first:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: white;
}
.book_item.first:after {
  top: initial;
  bottom: 10px;
}
.book_item.second:before,
.book_item.second:after,
.book_item.fifth:before,
.book_item.fifth:after {
  box-sizing: border-box;
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 17.5px;
  border-top: 5px solid white;
  border-bottom: 5px solid white;
}
.book_item.second:after,
.book_item.fifth:after {
  top: initial;
  bottom: 10px;
}
.book_item.third:before,
.book_item.third:after {
  box-sizing: border-box;
  content: "";
  position: absolute;
  top: 10px;
  left: 9px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 5px solid white;
}
.book_item.third:after {
  top: initial;
  bottom: 10px;
}
.book_item.fourth {
  top: -130px;
  height: 130px;
}
.book_item.fourth:before {
  box-sizing: border-box;
  content: "";
  position: absolute;
  top: 46px;
  left: 0;
  width: 100%;
  height: 17.5px;
  border-top: 5px solid white;
  border-bottom: 5px solid white;
}
.book_item.fifth {
  top: -100px;
  height: 100px;
}
.book_item.sixth {
  top: -140px;
  height: 140px;
}
.book_item.sixth:before {
  box-sizing: border-box;
  content: "";
  position: absolute;
  bottom: 31px;
  left: 0px;
  width: 100%;
  height: 5px;
  background-color: white;
}
.book_item.sixth:after {
  box-sizing: border-box;
  content: "";
  position: absolute;
  bottom: 10px;
  left: 9px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 5px solid white;
}
.book_item:nth-child(2) {
  -webkit-animation-delay: 416.6666666667ms;
  animation-delay: 416.6666666667ms;
}
.book_item:nth-child(3) {
  -webkit-animation-delay: 833.3333333333ms;
  animation-delay: 833.3333333333ms;
}
.book_item:nth-child(4) {
  -webkit-animation-delay: 1250ms;
  animation-delay: 1250ms;
}
.book_item:nth-child(5) {
  -webkit-animation-delay: 1666.6666666667ms;
  animation-delay: 1666.6666666667ms;
}
.book_item:nth-child(6) {
  -webkit-animation-delay: 2083.3333333333ms;
  animation-delay: 2083.3333333333ms;
}

.shelf {
  width: 300px;
  height: 5px;
  margin: 0 auto;
  background-color: white;
  position: relative;
}
.shelf:before,
.shelf:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  background-image: radial-gradient(
    rgba(255, 255, 255, 0.5) 30%,
    transparent 0
  );
  background-size: 10px 10px;
  background-position: 0 -2.5px;
  top: 200%;
  left: 5%;
  -webkit-animation: move 250ms linear infinite;
  animation: move 250ms linear infinite;
}
.shelf:after {
  top: 400%;
  left: 7.5%;
}

@-webkit-keyframes move {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: 10px;
  }
}

@keyframes move {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: 10px;
  }
}
@-webkit-keyframes travel {
  0% {
    opacity: 0;
    transform: translateX(300px) rotateZ(0deg) scaleY(1);
  }
  6.5% {
    transform: translateX(279.5px) rotateZ(0deg) scaleY(1.1);
  }
  8.8% {
    transform: translateX(273.6px) rotateZ(0deg) scaleY(1);
  }
  10% {
    opacity: 1;
    transform: translateX(270px) rotateZ(0deg);
  }
  17.6% {
    transform: translateX(247.2px) rotateZ(-30deg);
  }
  45% {
    transform: translateX(165px) rotateZ(-30deg);
  }
  49.5% {
    transform: translateX(151.5px) rotateZ(-45deg);
  }
  61.5% {
    transform: translateX(115.5px) rotateZ(-45deg);
  }
  67% {
    transform: translateX(99px) rotateZ(-60deg);
  }
  76% {
    transform: translateX(72px) rotateZ(-60deg);
  }
  83.5% {
    opacity: 1;
    transform: translateX(49.5px) rotateZ(-90deg);
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateX(0px) rotateZ(-90deg);
  }
}
@keyframes travel {
  0% {
    opacity: 0;
    transform: translateX(300px) rotateZ(0deg) scaleY(1);
  }
  6.5% {
    transform: translateX(279.5px) rotateZ(0deg) scaleY(1.1);
  }
  8.8% {
    transform: translateX(273.6px) rotateZ(0deg) scaleY(1);
  }
  10% {
    opacity: 1;
    transform: translateX(270px) rotateZ(0deg);
  }
  17.6% {
    transform: translateX(247.2px) rotateZ(-30deg);
  }
  45% {
    transform: translateX(165px) rotateZ(-30deg);
  }
  49.5% {
    transform: translateX(151.5px) rotateZ(-45deg);
  }
  61.5% {
    transform: translateX(115.5px) rotateZ(-45deg);
  }
  67% {
    transform: translateX(99px) rotateZ(-60deg);
  }
  76% {
    transform: translateX(72px) rotateZ(-60deg);
  }
  83.5% {
    opacity: 1;
    transform: translateX(49.5px) rotateZ(-90deg);
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateX(0px) rotateZ(-90deg);
  }
}

.loadingContainer {
  height: 100vh;
  background-color: #1c1d27;
}

@media (max-width: 1450px) {
  :root {
    font-size: 15px;
  }
}

@media (max-width: 1200px) {
  :root {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  :root {
    font-size: 13px;
  }
}

@media (max-width: 800px) {
  .pc {
    display: none;
  }

  .mob {
    display: flex;
  }

  .header {
    padding-top: 2.625rem;
    padding-left: 2.125rem;
    padding-right: 1.5rem;
  }

  .content {
    padding: 1.5rem;
    padding-bottom: 2.25rem;
  }

  .logoContainer {
    transform: translateY(3.5rem);
    -webkit-transform: translateY(3.5rem);
    -moz-transform: translateY(3.5rem);
    -ms-transform: translateY(3.5rem);
    -o-transform: translateY(3.5rem);
  }

  .header button {
    font-size: 1rem;
    line-height: 1.3125rem;
  }

  .mint {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .content {
    margin-top: -5rem;
  }
}

@media (max-width: 600px) {
  :root {
    font-size: 14px;
  }

  .minted {
    height: 18rem;
    margin: -2rem 0 0 0;
  }

  .augMint {
    min-height: 30rem !important;
    padding-bottom: 0 !important;
    /* padding-bottom: 0rem !important; */
  }

  .logoContainer {
    width: 7.375rem;
    height: 7.375rem;
  }

  .logoContainer img {
    max-width: 7.375rem;
  }
  .mobLinks a:first-child {
    margin-top: 4rem;
  }

  .text p {
    font-size: 1rem;
    line-height: 1.3125rem;
  }

  .mint {
    font-size: 1.5rem;
    line-height: 1.9375rem;
    padding: 1rem 2.1875rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .content {
    max-width: 85%;
    margin-top: -3rem;
  }

  .text {
    padding-bottom: 2rem;
    min-height: 23.1875rem;
  }

  .bottom {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }

  .bg {
    transition: filter 0.2s ease;
    background-image: url("./mobBg.png");
    background-size: cover;
    -webkit-transition: filter 0.2s ease;
    -moz-transition: filter 0.2s ease;
    -ms-transition: filter 0.2s ease;
    -o-transition: filter 0.2s ease;
  }
}
@media (max-height: 800px) {
  .content {
    margin-top: 0 !important;
  }
}

.Toastify__toast-theme--dark {
  background: #1c1d27 !important;
  box-shadow: 0 0 0 2px rgba(208, 235, 195, 0.8) !important;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 95vw !important;
    padding: 0 !important;
    left: 2.5vw !important;
    margin: auto !important;
  }
}

.Toastify__toast-container--bottom-center {
  bottom: 1rem !important;
}
